import {getProduct} from '../../api/productsApi'
const ProductDetailsPage = {
    state:()=>({
        productDetails:[],
    }),

    mutations:{
        setProductDetails(state, payload){
            state.productDetails = payload
        },
    },

    getters:{

    },

    actions:{
        fetchProductDetails({commit},product_id){
            return getProduct(product_id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setProductDetails', payload)
                        return payload
                    }
                })
        }
    }

}

export default ProductDetailsPage