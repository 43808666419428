var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loaderShow)?[_c('div',{staticClass:"wait"},[_c('Loader',{attrs:{"loaderType":_vm.loaderType}})],1)]:_vm._e(),_c('div',{staticClass:"product-details-page"},[(_vm.productDetails.responseCode == 200)?_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"product-details"},[(
                        _vm.productDetails.payload.product_details
                            .extra_images_with_labels.length == 0
                    )?_c('div',{staticClass:"product-img-container"},[_c('img',{attrs:{"src":_vm.productDetails.payload.product_details
                                .image_meta.n_image,"alt":_vm.productDetails.payload.product_details.name}})]):_c('div',{staticClass:"product-images"},[(_vm.windowWidth > 1100)?[_c('div',{staticClass:"extra-image-container"},_vm._l((_vm.productDetails.payload
                                    .product_details
                                    .extra_images_with_labels),function(image){return _c('div',{key:image.label,staticClass:"extra-image",on:{"click":function($event){return _vm.changeMainProductImage(image.img)}}},[_c('img',{class:image.img == _vm.mainProductImage
                                            ? 'active-extra-image'
                                            : '',attrs:{"src":image.img,"alt":_vm.productDetails.payload
                                            .product_details.name}})])}),0),_c('div',{staticClass:"main-image"},[_c('img',{attrs:{"src":_vm.mainProductImage,"alt":_vm.productDetails.payload.product_details
                                        .name}})])]:[_c('div',{staticClass:"extra-images-mobile"},[_c('Carousel',{attrs:{"settings":_vm.settings}},_vm._l((_vm.productDetails.payload
                                        .product_details
                                        .extra_images_with_labels),function(image){return _c('img',{key:image.label,attrs:{"src":image.img}})}),0)],1)]],2),_c('div',{staticClass:"product-details-container"},[_c('p',{staticClass:"vendor-name"},[_vm._v(" "+_vm._s(_vm.productDetails.payload.product_details .vendor_name)+" ")]),_c('h1',{staticClass:"product-name"},[_vm._v(" "+_vm._s(_vm.productDetails.payload.product_details.name)+" ")]),(
                            _vm.productDetails.payload.product_details.link !=
                                ''
                        )?_c('a',{staticClass:"vendor-link",attrs:{"href":_vm.productDetails.payload.product_details.link,"target":"_blank"}},[_vm._v(" view in "+_vm._s(_vm.productDetails.payload.product_details .vendor_name)+" ")]):_vm._e(),_c('p',{staticClass:"price-guarantee"},[_vm._v(" Price Match Guarantee "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"21","height":"21","fill":"none","viewBox":"0 0 21 21"}},[_c('path',{attrs:{"fill":"#D90303","fill-rule":"evenodd","d":"M10.793.748l1.618 2.555 2.743-1.275.803-.373-.006.885-.02 3.025 2.996.41.877.12-.484.741-1.652 2.534 2.3 1.965.672.575-.808.362-2.76 1.238.872 2.896.256.848-.876-.132-2.99-.45-.833 2.907-.244.85-.665-.583-2.272-1.997-2.272 1.997-.665.584-.244-.851-.832-2.908-2.991.45-.876.133.256-.848.871-2.896-2.76-1.238L0 11.91l.673-.575 2.3-1.965L1.32 6.836l-.484-.741.877-.12 2.997-.41-.02-3.025-.007-.885.803.373 2.743 1.275L9.847.748 10.32 0l.473.748zm1.956 3.28l2.4-1.115-.019 2.646-.005.703.697.095 2.622.36-1.446 2.216-.384.588.534.457 2.012 1.72-2.415 1.082-.641.288.203.673.762 2.533-2.616-.394-.695-.105-.194.676-.728 2.544-1.988-1.747-.528-.463-.528.463-1.988 1.747-.728-2.544-.194-.676-.695.105-2.616.394.762-2.534.203-.672-.641-.288-2.415-1.083 2.012-1.72.534-.456-.384-.588-1.446-2.217 2.622-.359.697-.095-.005-.703-.018-2.646 2.4 1.116.637.296.376-.594 1.415-2.235 1.415 2.235.376.594.638-.296zm-3.04 9.361l4.766-4.766-.566-.566-4.483 4.484-2.1-2.1-.566.565 2.383 2.383h.566z","clip-rule":"evenodd"}})])]),_c('div',{staticClass:"product-description",domProps:{"innerHTML":_vm._s(
                            _vm.productDetails.payload.product_details
                                .description
                        )}},[_vm._v(" "+_vm._s(_vm.productDetails.payload.product_details .description)+" ")]),_c('div',{staticClass:"product-price"},[_c('p',{class:_vm.requiredDomain == false ? 'blur' : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.productDetails.payload.product_details .item_price ))+" ")])]),_c('p',{staticClass:"expected-delivery"},[_vm._v(" Expected Delivery: 3 - 5 Days ")])])]),_c('div',{staticClass:"product-others-details"},[_c('Tabs',{attrs:{"isselected":0}},[_c('Tab',{attrs:{"title":"Specifications"},domProps:{"innerHTML":_vm._s(_vm.productDetails.payload.product_details.size)}},[_vm._v(" "+_vm._s(_vm.productDetails.payload.product_details.size)+" ")]),_c('Tab',{attrs:{"title":"Shipping & Returns"}},[_vm._v(" ########################################## ")])],1)],1),(
                    _vm.productDetails.payload.related_products_details.length >
                        0
                )?_c('div',{staticClass:"similar-products-container"},[_c('h1',[_vm._v("Similar Products")]),_c('div',{staticClass:"similar-products"},_vm._l((_vm.productDetails.payload
                            .related_products_details),function(product){return _c('div',{key:product.id,on:{"click":_vm.load}},[_c('router-link',{attrs:{"to":{
                                name: 'ProductDetailsPage',
                                params: { id: product.product_id },
                            }}},[_c('Cards',{attrs:{"requiredDomain":_vm.requiredDomain,"img":product.image_meta
                                        ? product.image_meta.p_image
                                        : require('@/assets/images/default-image1.png'),"cardname":product.name,"price":("₹ " + (_vm.convertToIndianNumberSystem(
                                        product.item_price
                                    )))}})],1)],1)}),0)]):_vm._e(),_c('Footer')],1):_c('div',[(_vm.showNotFound == true)?[_c('NotFound',{attrs:{"data":'Product',"content":_vm.content}})]:_vm._e()],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }