<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="product-details-page">
            <div
                class="content-container"
                v-if="productDetails.responseCode == 200"
            >
                <div class="product-details">
                    <div
                        v-if="
                            productDetails.payload.product_details
                                .extra_images_with_labels.length == 0
                        "
                        class="product-img-container"
                    >
                        <img
                            :src="
                                productDetails.payload.product_details
                                    .image_meta.n_image
                            "
                            :alt="productDetails.payload.product_details.name"
                        />
                        <!-- <a href="" class="view3d">{{
                        productDetails.payload.product_details.modal_url == 'undefined'
                            ? ''
                            : 'view in 3d'
                    }}</a> -->
                    </div>
                    <div v-else class="product-images">
                        <template v-if="windowWidth > 1100">
                            <div class="extra-image-container">
                                <div
                                    v-for="image in productDetails.payload
                                        .product_details
                                        .extra_images_with_labels"
                                    :key="image.label"
                                    class="extra-image"
                                    @click="changeMainProductImage(image.img)"
                                >
                                    <img
                                        :src="image.img"
                                        :alt="
                                            productDetails.payload
                                                .product_details.name
                                        "
                                        :class="
                                            image.img == mainProductImage
                                                ? 'active-extra-image'
                                                : ''
                                        "
                                    />
                                </div>
                            </div>
                            <div class="main-image">
                                <img
                                    :src="mainProductImage"
                                    :alt="
                                        productDetails.payload.product_details
                                            .name
                                    "
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="extra-images-mobile">
                                <Carousel :settings="settings">
                                    <img
                                        v-for="image in productDetails.payload
                                            .product_details
                                            .extra_images_with_labels"
                                        :key="image.label"
                                        :src="image.img"
                                    />
                                </Carousel>
                            </div>
                        </template>
                    </div>
                    <div class="product-details-container">
                        <p class="vendor-name">
                            {{
                                productDetails.payload.product_details
                                    .vendor_name
                            }}
                        </p>
                        <h1 class="product-name">
                            {{ productDetails.payload.product_details.name }}
                        </h1>
                        <a
                            v-if="
                                productDetails.payload.product_details.link !=
                                    ''
                            "
                            :href="productDetails.payload.product_details.link"
                            target="_blank"
                            class="vendor-link"
                        >
                            view in
                            {{
                                productDetails.payload.product_details
                                    .vendor_name
                            }}
                        </a>
                        <p class="price-guarantee">
                            Price Match Guarantee
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="21"
                                fill="none"
                                viewBox="0 0 21 21"
                            >
                                <path
                                    fill="#D90303"
                                    fill-rule="evenodd"
                                    d="M10.793.748l1.618 2.555 2.743-1.275.803-.373-.006.885-.02 3.025 2.996.41.877.12-.484.741-1.652 2.534 2.3 1.965.672.575-.808.362-2.76 1.238.872 2.896.256.848-.876-.132-2.99-.45-.833 2.907-.244.85-.665-.583-2.272-1.997-2.272 1.997-.665.584-.244-.851-.832-2.908-2.991.45-.876.133.256-.848.871-2.896-2.76-1.238L0 11.91l.673-.575 2.3-1.965L1.32 6.836l-.484-.741.877-.12 2.997-.41-.02-3.025-.007-.885.803.373 2.743 1.275L9.847.748 10.32 0l.473.748zm1.956 3.28l2.4-1.115-.019 2.646-.005.703.697.095 2.622.36-1.446 2.216-.384.588.534.457 2.012 1.72-2.415 1.082-.641.288.203.673.762 2.533-2.616-.394-.695-.105-.194.676-.728 2.544-1.988-1.747-.528-.463-.528.463-1.988 1.747-.728-2.544-.194-.676-.695.105-2.616.394.762-2.534.203-.672-.641-.288-2.415-1.083 2.012-1.72.534-.456-.384-.588-1.446-2.217 2.622-.359.697-.095-.005-.703-.018-2.646 2.4 1.116.637.296.376-.594 1.415-2.235 1.415 2.235.376.594.638-.296zm-3.04 9.361l4.766-4.766-.566-.566-4.483 4.484-2.1-2.1-.566.565 2.383 2.383h.566z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </p>
                        <div
                            class="product-description"
                            v-html="
                                productDetails.payload.product_details
                                    .description
                            "
                        >
                            {{
                                productDetails.payload.product_details
                                    .description
                            }}
                        </div>
                        <div class="product-price">
                            <p :class="requiredDomain == false ? 'blur' : ''">
                                ₹
                                {{
                                    convertToIndianNumberSystem(
                                        productDetails.payload.product_details
                                            .item_price,
                                    )
                                }}
                            </p>
                        </div>
                        <!-- <Button primary name="Replace" style="margin: 15px 0" /> -->
                        <p class="expected-delivery">
                            Expected Delivery: 3 - 5 Days
                        </p>
                    </div>
                </div>

                <div class="product-others-details">
                    <Tabs :isselected="0">
                        <Tab
                            title="Specifications"
                            v-html="productDetails.payload.product_details.size"
                        >
                            {{ productDetails.payload.product_details.size }}
                        </Tab>
                        <Tab title="Shipping & Returns">
                            ##########################################
                        </Tab>
                    </Tabs>
                </div>

                <div
                    v-if="
                        productDetails.payload.related_products_details.length >
                            0
                    "
                    class="similar-products-container"
                >
                    <h1>Similar Products</h1>
                    <div class="similar-products">
                        <div
                            v-for="product in productDetails.payload
                                .related_products_details"
                            :key="product.id"
                            @click="load"
                        >
                            <router-link
                                :to="{
                                    name: 'ProductDetailsPage',
                                    params: { id: product.product_id },
                                }"
                            >
                                <Cards
                                    :requiredDomain="requiredDomain"
                                    :img="
                                        product.image_meta
                                            ? product.image_meta.p_image
                                            : require('@/assets/images/default-image1.png')
                                    "
                                    :cardname="product.name"
                                    :price="
                                        `₹ ${convertToIndianNumberSystem(
                                            product.item_price,
                                        )}`
                                    "
                                />
                            </router-link>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <div v-else>
                <template v-if="showNotFound == true">
                    <NotFound :data="'Product'" :content="content" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import ProductDetailsPageModule from 'store/modules/ProductDetailsPage'
// import Button from 'componentsv2/Button'
import Carousel from 'componentsv2/Carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Tabs from 'componentsv2/Tabs'
import Tab from 'componentsv2/Tab'
import Cards from 'componentsv2/Cards'
import Footer from 'componentsv2/Footer'
import NotFound from 'componentsv2/NotFound'
import Loader from 'componentsv2/Loader'
import LoaderHandler from 'mixins/loader'

export default {
    name: 'ProductDetailsPage',
    components: {
        // Button,
        Tabs,
        Tab,
        Cards,
        Footer,
        NotFound,
        Carousel,
        Loader,
    },
    props: {
        requiredDomain: Boolean,
    },
    mixins: [RegisterStoreModule, ConvertToPrice, LoaderHandler],
    data() {
        return {
            showNotFound: false,
            mainProductImage: '',
            settings: {
                dots: true,
                arrows: false,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            content: 'The link may be broken, we have taken the page down',
        }
    },
    computed: {
        ...mapState({
            productDetails: state => state.ProductDetailsPage.productDetails,
            windowWidth: state => state.AppModule.windowWidth,
        }),
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            this.loaderShow = true
            this.fetchProductDetails(this.$route.params.id)
                .then(response => {
                    if (response.responseCode != 200) {
                        this.showNotFound = true
                        setTimeout(() => {
                            this.loaderShow = false
                            document.querySelector('body').style.overflow =
                                'auto'
                        }, 1000)
                    } else {
                        this.showNotFound = false
                        setTimeout(() => {
                            this.loaderShow = false
                            document.querySelector('body').style.overflow =
                                'auto'
                        }, 1000)
                    }
                })
                .catch(err => {
                    setTimeout(() => {
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'
                    }, 1000)
                })
        },
    },
    // mounted() {
    //     setTimeout(() => {
    //         this.loaderShow = false
    //     }, 1000)
    // },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Product details',
    },
    created() {
        this.registerStoreModule('ProductDetailsPage', ProductDetailsPageModule)
        window.scrollTo(0, 0)
        this.fetchProductDetails(this.$route.params.id)
            .then(response => {
                if (response.responseCode != 200) {
                    this.showNotFound = true
                } else {
                    this.showNotFound = false

                    document.querySelector('body').style.overflow = 'auto'
                    if (
                        response.payload.product_details
                            .extra_images_with_labels.length > 1
                    ) {
                        this.mainProductImage =
                            response.payload.product_details.extra_images_with_labels[0].img
                    }
                }
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
    },
    destroyed() {
        // this.$store.unregisterModule('ProductDetailsPage')
    },
    methods: {
        ...mapActions({
            fetchProductDetails: 'fetchProductDetails',
        }),

        startQuiz() {
            window.open(`${this.url}?startQuiz=true`)
        },
        changeMainProductImage(image) {
            this.mainProductImage = image
        },
        load() {
            this.loaderShow = true
            setTimeout(() => {
                this.loaderShow = false
            }, 1000)
        },
    },
}
</script>

<style lang="scss" scoped>
@import './ProductDetailsPage.scss';
</style>
